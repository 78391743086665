import React from 'react'
import { useTranslation } from 'react-i18next'
import { Transition } from 'react-transition-group'

const CustomToastForWinInfo = ({ appearance, children, onDismiss, autoDismissTimeout }) => {
  const { t } = useTranslation('main')
  const { isWinning, escapeRate, winningAmount, currencyCode, message } = children || {}

  return (
    <Transition in timeout={autoDismissTimeout} appear>
      {(state) => (
        <div
          className={`toast-container my-2 toast-animation-${state}`}
          style={{ background: appearance === 'error' ? 'red' : '#123405' }}
        >
          {isWinning
            ? (
              <>
                <div>
                  <div>{t('toastContentForWinInfo.cashedOut')}</div>
                  <div className='toast-escape-container'>{parseFloat(escapeRate).toFixed(2)}x</div>
                </div>
                <div className='toast-winning-part'>
                  <div>{t('toastContentForWinInfo.winText')}</div>
                  <div className='d-flex justify-content-center mt-1'>{parseFloat(winningAmount).toFixed(2)} <span className='ml-1' dangerouslySetInnerHTML={{ __html: currencyCode }} /></div>
                </div>
                <div className='toast-cancel-btn' onClick={onDismiss}>X</div>
              </>
              )
            : (
              <div className='success-message-toast'>
                {message}
                {!!currencyCode && (<span className='ml-1' dangerouslySetInnerHTML={{ __html: currencyCode }} />)}
              </div>
              )}
        </div>
      )}
    </Transition>
  )
}

export default CustomToastForWinInfo
